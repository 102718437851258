export const LINE_CHART_COLOR_PALETTE = {
  ENERGY: '#9753F6',
  ENTHUSIASM: '#F8CB5E',
  EFFECTIVENESS: '#38CBEC',
}

export const BAR_CHART_COLOR_PALETTE = {
  HIGH_RISK: '#E3653E',
  MEDIUM_RISK: '#ECEBF7',
  THRIVING: '#5DDDB7',
}
