export * from './route'
export * from './sentry'

export enum RangeOptionKey {
  Last30Days = 'Last30Days',
  Last60Days = 'Last60Days',
  Last180Days = 'Last180Days',
}

export enum SelcedtedView {
  Chart,
  Table,
}

export enum EngagementLevelsType {
  HighlyEngaged,
  AtRiskOfBurnout,
}

export enum TableView {
  Percent,
  Number,
}

export enum BarDataKeys {
  Bottom = 'bottom',
  Top = 'top',
  Middle = 'middle',
}

export enum BarType {
  HighlyEngaged = 'Highly engaged',
  ModeratelyEngaged = 'Moderately engaged',
  AtRiskForBurnout = 'At risk for burnout',
}

export enum PulseTab {
  CompanyWellBeing = 'CompanyWellBeing',
  AppUsage = 'AppUsage',
}

export enum AppCategory {
  Microsteps = 'Microsteps',
  Challenges = 'Challenges',
  LearnModules = 'LearnModules',
  Resets = 'Resets',
}

export enum AppUsageChartId {
  ActiveUsers = 'ActiveUsers',
  TotalSignUps = 'TotalSignUps',
  DailyCheckInUsers = 'DailyCheckInUsers',
  DailyCheckInResponse = 'DailyCheckInResponse',
}
