import { ThemeProvider } from '@mui/material'
import { ProtectedRoute } from '@thriveglobal/thrive-web-auth-core'
import {
  isMicrosoftTeamsTest,
  useSetPageTitle,
} from '@thriveglobal/thrive-web-core'
import {
  ThriveFullscreenLoading,
  useTheme,
} from '@thriveglobal/thrive-web-leafkit'
import React, { lazy, Suspense } from 'react'
import { defineMessage, useIntl } from 'react-intl'
import { BrowserRouter, Redirect, Switch } from 'react-router-dom'

import ScrollToTop from '../components/ScrollToTop'
import { useRoutes } from '../hooks/useRoutes'

const AdminDashboard = lazy(() => import('../components/AdminDashboardPage'))
const BeforeLaunch = lazy(() => import('../pages/BeforeLaunch'))
const AccessRestricted = lazy(() => import('../pages/AccessRestricted'))
const EngagementLevels = lazy(() => import('../components/EngagementLevels'))
const AdminDimensionDetailPage = lazy(
  () => import('../components/AdminDimensionDetailPage')
)

const PULSE_PAGE_TITLE = defineMessage({
  defaultMessage: 'Thrive - Pulse',
  description: 'Thrive pulse page title',
})

const Router: React.FC = () => {
  const theme = useTheme()
  const { formatMessage } = useIntl()
  const Routes = useRoutes()

  useSetPageTitle(formatMessage(PULSE_PAGE_TITLE))

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Suspense fallback={<ThriveFullscreenLoading />}>
          <ScrollToTop>
            <Switch>
              <ProtectedRoute
                path={Routes.AccessRestricted}
                component={AccessRestricted}
              />
              <ProtectedRoute
                path={Routes.BeforeLaunch}
                component={(params) => {
                  const state = params.location.state
                  return state &&
                    (state?.age || typeof state?.age === 'number') ? (
                    <BeforeLaunch />
                  ) : (
                    <Redirect to={{ pathname: Routes.PersonalDashboard }} />
                  )
                }}
              />
              <ProtectedRoute
                path={Routes.EngagementLevels}
                component={EngagementLevels}
              />
              <ProtectedRoute
                path={`${Routes.AdminDashboard}/:dimensionDetailType`}
                component={AdminDimensionDetailPage}
              />
              <ProtectedRoute
                path={Routes.AdminDashboard}
                component={AdminDashboard}
              />
              {isMicrosoftTeamsTest() && <Redirect to={Routes.BeforeLaunch} />}
            </Switch>
          </ScrollToTop>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default Router
