import { useMemo } from 'react'
import {
  getPlatform,
  isRunningInReactNativeWebView,
} from '@thriveglobal/thrive-web-core'
import {
  Routes,
  ThriveThreeRoutes as Thrive3Routes,
  OtherThriveApps,
  SharedRoutes,
} from '../enums/route'

type CombinedSharedRoutes = typeof SharedRoutes & typeof OtherThriveApps
type ThriveThreeRoutes = CombinedSharedRoutes & typeof Thrive3Routes
type ThriveRoutes = CombinedSharedRoutes & typeof Routes

export const useRoutes = (): ThriveThreeRoutes | ThriveRoutes => {
  const isNonReactNativeHybridPlatform = useMemo(() => {
    const platform = getPlatform()
    return (
      (platform === 'hybrid-android' || platform === 'hybrid-ios') &&
      !isRunningInReactNativeWebView()
    )
  }, [])

  return {
    ...SharedRoutes,
    ...OtherThriveApps,
    ...(isNonReactNativeHybridPlatform ? Routes : Thrive3Routes),
  }
}
