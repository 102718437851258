import { FC, ReactNode } from 'react'
import DemoModeContextContainer from './DemoModeContextContainer'
import { MonthPickerProvider } from './MonthPickerContext'

/** Renders contexts that are relied on across the entirety of the pulse app */
const PulseAppContexts: FC<{
  children: ReactNode
}> = ({ children }) => {
  return (
    <MonthPickerProvider>
      <DemoModeContextContainer>{children}</DemoModeContextContainer>
    </MonthPickerProvider>
  )
}

export default PulseAppContexts
