import { defineMessages } from 'react-intl'
import { RangeOptionKey } from '../enums'

export const SECONDS_A_MINUTE = 60
export const SECONDS_A_HOUR = SECONDS_A_MINUTE * 60
export const SECONDS_A_DAY = SECONDS_A_HOUR * 24
export const SECONDS_A_WEEK = SECONDS_A_DAY * 7

export const MILLISECONDS_A_SECOND = 1e3
export const MILLISECONDS_A_MINUTE = SECONDS_A_MINUTE * MILLISECONDS_A_SECOND
export const MILLISECONDS_A_HOUR = SECONDS_A_HOUR * MILLISECONDS_A_SECOND
export const MILLISECONDS_A_DAY = SECONDS_A_DAY * MILLISECONDS_A_SECOND
export const MILLISECONDS_A_WEEK = SECONDS_A_WEEK * MILLISECONDS_A_SECOND
export const MILLISECONDS_A_MONTH = 4 * SECONDS_A_WEEK * MILLISECONDS_A_SECOND

export const MAX_DAYS_BEFORE_LAUNCH = 14

export const WEEKDAY = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 0,
}

export const MONTHS_IN_A_YEAR = 12
export const LAST_DAY_OF_PREVIOUS_MONTH = 0
export const FIRST_DAY_OF_MONTH = 1
export const THREE_MONTHS = 3
export const ONE_YEAR = 1
export const ONE_MONTH = 1

const RANGE_OPTIONS_TEXT = defineMessages({
  Last30Days: {
    defaultMessage: 'Last 30 Days',
    description: 'Set range selector to display data from last 30 days',
  },
  Last60Days: {
    defaultMessage: 'Last 60 Days',
    description: 'Set range selector to display data from last 60 days',
  },
  Last180Days: {
    defaultMessage: 'Last 180 Days',
    description: 'Set range selector to display data from last 180 days',
  },
})

export const rangeOptionsMap = new Map([
  [
    RangeOptionKey.Last30Days,
    {
      optionText: RANGE_OPTIONS_TEXT.Last30Days,
      value: MILLISECONDS_A_DAY * 30,
      numberOfDays: 30,
    },
  ],
  [
    RangeOptionKey.Last60Days,
    {
      optionText: RANGE_OPTIONS_TEXT.Last60Days,
      value: MILLISECONDS_A_DAY * 60,
      numberOfDays: 60,
    },
  ],
  [
    RangeOptionKey.Last180Days,
    {
      optionText: RANGE_OPTIONS_TEXT.Last180Days,
      value: MILLISECONDS_A_DAY * 180,
      numberOfDays: 180,
    },
  ],
])

export const PERSONAL_DASHBOARD_TIME_RANGE = RangeOptionKey.Last30Days
