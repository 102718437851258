import { ComputedBarDatum } from '@nivo/bar'
import { Scale } from '@nivo/scales'
import { BAR_CHART_COLOR_PALETTE } from '../constants'
import {
  AdminDashboardInsightsQuery,
  RiskCohort,
  Surveys,
} from '../graphql/generated/autogenerated'
import { BarChartMap, Benchmark, CustomizedChartData } from '../types'
import { BarDataKeys } from '../enums'

const mapRiskCohortStatsToBarChart = (
  riskCohortStats:
    | AdminDashboardInsightsQuery['pulse']['insights']['energyRiskCohortStats']
    | AdminDashboardInsightsQuery['pulse']['insights']['enthusiasmRiskCohortStats']
    | AdminDashboardInsightsQuery['pulse']['insights']['efficacyRiskCohortStats']
) => {
  let highRiskChangeSinceLastPeriod: number,
    percentageOfHighRisk: number,
    percentageOfMediumRisk: number,
    percentageOfThriving: number

  const data = riskCohortStats.map(
    ({ riskCohort, changeSinceLastPeriod, relativeSizePercentage }) => {
      const relativePercentage = Math.round(relativeSizePercentage * 100)
      switch (riskCohort) {
        case RiskCohort.HighRisk:
          highRiskChangeSinceLastPeriod = Math.round(
            changeSinceLastPeriod * 100
          )
          percentageOfHighRisk = relativePercentage
          return {
            riskCohort: RiskCohort.HighRisk,
            relativePercentage,
            color: BAR_CHART_COLOR_PALETTE.HIGH_RISK,
          }
        case RiskCohort.MediumRisk:
          percentageOfMediumRisk = relativePercentage
          return {
            riskCohort: RiskCohort.MediumRisk,
            relativePercentage,
            color: BAR_CHART_COLOR_PALETTE.MEDIUM_RISK,
          }
        case RiskCohort.Thriving:
          percentageOfThriving = relativePercentage
          return {
            riskCohort: RiskCohort.Thriving,
            relativePercentage,
            color: BAR_CHART_COLOR_PALETTE.THRIVING,
          }
      }
    }
  )
  return {
    data,
    highRiskChangeSinceLastPeriod,
    percentageOfHighRisk,
    percentageOfMediumRisk,
    percentageOfThriving,
  }
}

export const createBarChartMap = (
  barChartDataMap: BarChartMap,
  insights: AdminDashboardInsightsQuery['pulse']['insights'] | undefined
) => {
  barChartDataMap.set(
    Surveys.EnergyLevels,
    mapRiskCohortStatsToBarChart(insights.energyRiskCohortStats)
  )
  barChartDataMap.set(
    Surveys.Enthusiasm,
    mapRiskCohortStatsToBarChart(insights.enthusiasmRiskCohortStats)
  )
  barChartDataMap.set(
    Surveys.Efficacy,
    mapRiskCohortStatsToBarChart(insights.efficacyRiskCohortStats)
  )
  return barChartDataMap
}

export const getFormattedBenchmarkData = (
  bars: ComputedBarDatum<Benchmark>[],
  yScale: Scale<number, number>,
  benchmarkData: Benchmark[]
) => {
  const formatedData: CustomizedChartData[] = []
  bars.forEach((bar, i) => {
    // Because the same bar has 3 data points, we only want to add the benchmark data once
    if (bar.data.id === BarDataKeys.Bottom) {
      // skip the empty value to ensure the benchmark line is not broken
      !bar.data.data?.isEmpty &&
        formatedData.push({
          x: bar.x + bar.width / 2,
          y0: yScale(0),
          y1: yScale(benchmarkData[i].benchmarkPercentage),
        })
    }
  })

  return formatedData
}
