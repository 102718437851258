import {
  AdminDashboardInsightsQuery,
  DailyAssessmentStatsResponse,
  Surveys,
  WeeklyAssessmentStatsResponse,
} from '../graphql/generated/autogenerated'
import { LineChartAvarage, Unarray, YScaleConfig } from '../types'

type UnarrayAssessmentStatsResponse = Unarray<
  AdminDashboardInsightsQuery['pulse']['insights']['assessmentStats']
>

const isDailyAssessmentStatsResponse = (
  data: UnarrayAssessmentStatsResponse
): data is DailyAssessmentStatsResponse =>
  data.__typename === 'DailyAssessmentStatsResponse'

const isWeeklyAssessmentStatsResponse = (
  data: UnarrayAssessmentStatsResponse
): data is WeeklyAssessmentStatsResponse =>
  data.__typename === 'WeeklyAssessmentStatsResponse'

const getYAxisTicks = (min: number, max: number) => {
  const ticks = []
  const start = min <= 1 ? 0 : min - 1
  for (let i = start; i <= max; i++) {
    ticks.push(i * 10)
  }
  return ticks
}

export const mapAssessmentStatsForAdminLineChart = (
  assessmentStats: AdminDashboardInsightsQuery['pulse']['insights']['assessmentStats']
): {
  energyStats: LineChartAvarage['data']
  enthusiasmStats: LineChartAvarage['data']
  effectivenessStats: LineChartAvarage['data']
  yAxisConfig: YScaleConfig
  isStatsData: boolean
} => {
  const energyStats = [],
    enthusiasmStats = [],
    effectivenessStats = []

  let minValuePercentage = 0,
    maxValuePercentage = 0,
    noEnergyValueCounter = 0,
    noEnthusiasmValueCounter = 0,
    noEffectivenessValueCounter = 0

  assessmentStats.forEach((data) => {
    if (data.__typename === 'UngroupedAssessmentStatsResponse') {
      // only grouped (day/month/year) assessment stats are applicable to line charts
      // and have the requisite fields to plot on a line chart
      return
    }
    const { valuePercentage, survey, year, month } = data
    // finding the max and min value of the percentage in order to generate YAxisConfig
    if (!minValuePercentage) minValuePercentage = valuePercentage
    if (!maxValuePercentage) maxValuePercentage = valuePercentage
    if (valuePercentage < minValuePercentage && valuePercentage !== 0)
      minValuePercentage = valuePercentage
    if (valuePercentage > maxValuePercentage)
      maxValuePercentage = valuePercentage

    let day = new Date(year, month, 0).getDate()
    if (isDailyAssessmentStatsResponse(data)) {
      day = data.day
    }

    const date = new Date(year, month - 1, day).toString()
    const x = isWeeklyAssessmentStatsResponse(data) ? data.weekOfYear : date
    const y = valuePercentage ? valuePercentage : null

    switch (survey) {
      case Surveys.EnergyLevels:
        if (!y) noEnergyValueCounter++
        energyStats.push({ x, y })
        break
      case Surveys.Enthusiasm:
        if (!y) noEnthusiasmValueCounter++
        enthusiasmStats.push({ x, y })
        break
      case Surveys.Efficacy:
        if (!y) noEffectivenessValueCounter++
        effectivenessStats.push({ x, y })
        break
    }
  })

  const roundedMin = Math.round(minValuePercentage / 10)
  const roundedMax = Math.ceil(maxValuePercentage / 10)

  return {
    energyStats,
    enthusiasmStats,
    effectivenessStats,
    yAxisConfig: {
      // the lowest value on y axis should be -10 compared to the lowest percentage value
      // Unless it is smaller than 10
      min: roundedMin * 10 > 10 ? roundedMin * 10 - 10 : roundedMin,
      max: roundedMax * 10,
      ticks: getYAxisTicks(roundedMin, roundedMax),
    },
    isStatsData:
      noEnergyValueCounter +
        noEnthusiasmValueCounter +
        noEffectivenessValueCounter !==
      assessmentStats.filter(
        (data) => data.__typename !== 'UngroupedAssessmentStatsResponse'
      ).length,
  }
}

export const isFirstHalf = (index: number, numberOfLineChartPoints: number) =>
  index % numberOfLineChartPoints < numberOfLineChartPoints / 2
