import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'
import Root from './root.component'
import { ErrorBoundary } from '@thriveglobal/thrive-web-leafkit'

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    return (
      <ErrorBoundary>
        <></>
      </ErrorBoundary>
    )
  },
})

export const { bootstrap, mount, unmount } = lifecycles
