export enum OtherThriveApps {
  // other thrive apps
  ThriveRoot = '/',
  Reset = '/reset',
  ResetHome = '/reset',
  ResetItem = '/reset/thrive/:id',
  Learn = '/learn/courses',
  Challenges = '/challenges',
  Integrations = '/profile/integrations',
  Journeys = '/journeys',
}

export enum SharedRoutes {
  // shared routes for both thrive3 & non-thrive3 apps
  BeforeLaunch = '/pulse/before-launch',
  AccessRestricted = '/pulse/access-restricted',
}

export enum Routes {
  // insights/pulse app
  PersonalDashboard = '/pulse',
  AdminDashboard = '/pulse/admin',
  Welcome = '/pulse/welcome',
  EngagementLevels = '/pulse/engagement-levels',
}

export enum ThriveThreeRoutes {
  // insights/pulse app
  PersonalDashboard = '/insights',
  AdminDashboard = '/pulse',
  Welcome = '/insights/welcome',
  EngagementLevels = '/pulse/engagement-levels',
}

export enum AdminDimensionDetailRoutes {
  Belonging = 'belonging',
  Effectiveness = 'effectiveness',
  Energy = 'energy',
  Enthusiasm = 'enthusiasm',
  Productivity = 'productivity',
  Retention = 'retention',
}
