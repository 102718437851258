import {
  FIRST_DAY_OF_MONTH,
  LAST_DAY_OF_PREVIOUS_MONTH,
  MONTHS_IN_A_YEAR,
  ONE_YEAR,
  THREE_MONTHS,
} from '../constants/time'
import { MonthYearInput } from '../graphql/generated/autogenerated'

export const getStartTimeOfTheDay = (date: number) =>
  new Date(date).setHours(0, 0, 0, 0)

export const dateToIsoStringWithoutTime = (date: Date) =>
  new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000))
    .toISOString()
    .split('T')[0]

/**
 * Formats a date string in the "YYYY-MM" format.
 *
 * This function receives a date string, validates it, and then formats that date into "YYYY-MM".
 *
 * @param {string} date - A date string in either "YYYY-MM-DD" or "YYYY-MM" format.
 *
 * @returns {(string|undefined)} A string representing the year and the month of the input date in the "YYYY-MM" format. If the input date string is falsy, the function returns undefined.
 *
 */
export const formatDateStringToYearMonth = (date: string) => {
  if (!date) return undefined

  const match = date.match(/^(\d{4})-(\d{2})(?:-(\d{2}))?$/)

  if (!match) {
    throw new Error('Invalid date format. Expected YYYY-MM-DD or YYYY-MM')
  }

  const [, year, month] = match

  return `${year}-${month}`
}

/**
 * Formats a Date object into the "YYYY-MM" format.
 *
 * @param {Date} date - A Date object.
 *
 * @returns {string} A string representing the year and the month of the input date in the "YYYY-MM" format.
 */
export const formatDateObjToYearMonth = (date: Date): string => {
  const year = date.getFullYear()
  const month = `${date.getMonth() + 1}`.padStart(2, '0')

  return `${year}-${month}`
}

/**
 * Extract Year and Month from "YYYY-MM" format.
 *
 * @param {yearMonth} string - String with"YYYY-MM" format
 *
 * @returns {MonthYearInput} An object with the properties `year` and `month`.
 */
export const extractYearMonth = (yearMonth: string): MonthYearInput => {
  const parts = yearMonth.split('-')

  if (parts.length !== 2) {
    throw new Error('Invalid input: input should be in the format "YYYY-MM".')
  }

  const [year, month] = parts.map((part) => {
    const num = Number(part)
    if (Number.isNaN(num)) {
      throw new Error('Invalid input: year and month should be numbers.')
    }
    return num
  })

  return { year, month }
}

export const getDefaultStartEndDate = () => {
  const currentDateObj = new Date()
  const currentYear = currentDateObj.getFullYear()
  const currentMonth = currentDateObj.getMonth()

  // Set endDate to the last day of the month that is one month before the current month
  const endDate = new Date(
    currentYear,
    currentMonth,
    LAST_DAY_OF_PREVIOUS_MONTH
  )

  // Adjust startDate to three months before the new endDate
  let startDate: Date
  const endMonth = endDate.getMonth()
  if (endMonth >= THREE_MONTHS) {
    startDate = new Date(
      endDate.getFullYear(),
      endMonth - THREE_MONTHS,
      FIRST_DAY_OF_MONTH
    )
  } else {
    startDate = new Date(
      endDate.getFullYear() - ONE_YEAR,
      MONTHS_IN_A_YEAR + endMonth - THREE_MONTHS,
      FIRST_DAY_OF_MONTH
    )
  }

  return {
    startDate,
    endDate,
  }
}
